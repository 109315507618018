/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Documents from 'components/documents/Documents';

import classes from './Favorites.module.scss';
const Favorites = () => {
  const [key, setKey] = useState('myView');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Tabs
      id='homeView'
      activeKey={key}
      onSelect={(k) => setKey(k)}
      unmountOnExit={true}
      mountOnEnter={true}
      className={`mb-3 ${classes.stickyTab}`}>
      {/* <Tab
        eventKey='myView'
        title={
          <span className='d-flex'>
            <i
              className={`icon icon__${
                key === 'myView' ? 'my-view-active' : 'my-view'
              }`}
            />{' '}
            My View
          </span>
        }>
        <Fade>
          <ConfigureMyView page={CONFIGURE_VIEW.myView} />
        </Fade>
        <DndProvider backend={HTML5Backend}>
          <TileContainer page={CONFIGURE_VIEW.myView} />
        </DndProvider>
      </Tab>

      <Tab
        eventKey='myDefault'
        title={
          <span className='d-flex'>
            <i
              className={`icon icon__${
                key === 'myDefault' ? 'my-default-active' : 'my-default'
              }`}
            />{' '}
            My Default
          </span>
        }>
        {key === 'myDefault' && (
          <DndProvider backend={HTML5Backend}>
            <TileContainer
              page={CONFIGURE_VIEW.myDefault}
              activeTab={key}
            />
          </DndProvider>
        )}
      </Tab> */}

      <Tab
        eventKey='myDocs'
        title={
          <span className='d-flex'>
            <i
              className={`icon icon__${
                key === 'myDocs'
                  ? 'my-view-documents-active'
                  : 'my-view-documents'
              }`}
            />{' '}
            Documents
          </span>
        }>
        <Documents />
      </Tab>
    </Tabs>
  );
};

export default Favorites;
